import Axios from 'axios';
import { API_PREFIX, BASE_URL } from '../config/apiConfig';
// import Product from './resources/Product';
// import AgencyGroup from './resources/AgencyGroup';
// import AgencyUsers from './resources/AgencyUsers';

class ApiClientSingleton {
  constructor() {
    const httpConfig = {
      baseURL: BASE_URL + API_PREFIX,
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache'
      }
    };
    this.httpClient = Axios.create(httpConfig);

    // this.product = new Product(this.httpClient, '/application/BrowseProduct');
    // this.agencyGroup = new AgencyGroup(this.httpClient, '/groups');
    // this.agencyUser = new AgencyUsers(this.httpClient, '');
  }

  static get instance() {
    return this._instance || new ApiClientSingleton();
  }
}

const ApiClient = ApiClientSingleton.instance;
export default ApiClient;
