import { takeLatest, call, put } from 'redux-saga/effects';
import Axios from 'axios';
import ApiClient from '../../../Services/ApiClient';
import { SP_CLOUD_API_ENDPOINT } from '../../../config/apiConfig';
import createFetchTypes from '../../utils/createFetchTypes';
import { showLoader, hideLoader } from '../../popUps/loaderReducer';

const FETCH_GIT_HISTORY_BASE = 'FETCH_GIT_HISTORY_BASE';
const FETCH_GIT_PLUGIN_LINKAGE_DATA_FOR_CERT = 'FETCH_GIT_PLUGIN_LINKAGE_DATA_FOR_CERT';
const FETCH_GIT_HISTORY_SUCCESS = 'FETCH_GIT_HISTORY_SUCCESS';
const FETCH_GIT_PLUGIN_LINKAGE_DATA_SUCCESS_FOR_CERT = 'FETCH_GIT_PLUGIN_LINKAGE_DATA_SUCCESS_FOR_CERT';

const SAVE_GIT_URL = createFetchTypes(
  'Marketplace/SAVE_GIT_URL'
);

export const fetchGitPluginLinkageDataForCert = (data, callbackplugin) => ({
  type: FETCH_GIT_PLUGIN_LINKAGE_DATA_FOR_CERT,
  payload: {
    data,
  },
  callbackplugin
});

export const fetchGitHistory = (data) => ({
  type: FETCH_GIT_HISTORY_BASE,
  payload: {
    data,
  }
});

export const saveGitUrl = {
  base: (data, callback) => ({
    type: SAVE_GIT_URL.BASE,
    data,
    url: 'spwebservice/api/Plugin/UploadPlugin',
    callback
  }),
  request: () => ({ type: SAVE_GIT_URL.REQUEST }),
  success: payload => ({ type: SAVE_GIT_URL.SUCCESS, payload }),
  failure: error => ({ type: SAVE_GIT_URL.FAILURE, error })
};

export const saveGitSpcUrl = {
  base: (data, callback) => ({
    type: SAVE_GIT_URL.BASE,
    data,
    url: 'spwebservice/api/Plugin/UploadPlugin',
    callback
  }),
  request: () => ({ type: SAVE_GIT_URL.REQUEST }),
  success: payload => ({ type: SAVE_GIT_URL.SUCCESS, payload }),
  failure: error => ({ type: SAVE_GIT_URL.FAILURE, error })
};

export const fetchGitHistorySuccess = (data) => ({
  type: FETCH_GIT_HISTORY_SUCCESS,
  data
});

export const fetchGitPluginLinkageDataSuccessForCert = (data) => ({
  type: FETCH_GIT_PLUGIN_LINKAGE_DATA_SUCCESS_FOR_CERT,
  data
});

export default function gitHistory(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_GIT_HISTORY_BASE:
    case FETCH_GIT_PLUGIN_LINKAGE_DATA_FOR_CERT:
    case SAVE_GIT_URL.BASE:
      return {
        ...state,
      };
    case FETCH_GIT_HISTORY_SUCCESS:
      return {
        ...state,
        historyList: action.data.history,
        orgDetails: action.data.orgDetails
      };
    case FETCH_GIT_PLUGIN_LINKAGE_DATA_SUCCESS_FOR_CERT:
      return {
        ...state,
        getValidatedPluginLinkageDataForCert: action.data
      };
    case SAVE_GIT_URL.SUCCESS:
      return {
        ...state,
        historyListItem: action.payload // we will remove this later
      };
    case SAVE_GIT_URL.FAILURE:
      return {
        ...state,
        errorList: action.error
      };
    default:
      return state;
  }
}

export function* gitValidatedPluginForCertSaga(action) {
  yield put(showLoader());
  try {
    const baseUrl = SP_CLOUD_API_ENDPOINT;
    const customhttpConfig = {
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    ApiClient.httpClient = Axios.create(customhttpConfig);
    const response = yield call(ApiClient.httpClient.get, `spwebservice/api/Plugin/linkage?appId=${action.payload.data.appId}`);
    if (response.data !== undefined) {
      yield put(fetchGitPluginLinkageDataSuccessForCert(response.data));
      action.callbackplugin();
      yield put(hideLoader());
    }
  } catch (error) {
    yield put(hideLoader());
  }
}

export function* gitHistorySaga(action) {
  yield put(showLoader());
  const baseUrl = SP_CLOUD_API_ENDPOINT;
  const customhttpConfig = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  ApiClient.httpClient = Axios.create(customhttpConfig);
  const response = yield call(ApiClient.httpClient.get, `spwebservice/api/Plugin/GetUploadedPluginHistory?orgId=${action.payload.data}`);
  if (response.data) {
    yield put(fetchGitHistorySuccess(response.data));
    yield put(hideLoader());
  }
}

export function* saveGitSaga(action) {
  yield put(showLoader());
  try {
    const params = action.data;
    const baseUrl = SP_CLOUD_API_ENDPOINT;
    const customhttpConfig = {
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    ApiClient.httpClient = Axios.create(customhttpConfig);
    const response = yield ApiClient.httpClient.post(action.url, params);
    if (response.data || response.statusText === 'OK') {
      yield put(saveGitUrl.success(response.data));
      const messages = undefined;
      yield put(saveGitUrl.failure(messages));
      action.callback();
    }
    yield put(hideLoader());
  } catch (error) {
    let message;
    if (error.response.data.message !== undefined) {
      message = error.response.data.message;
    } else {
      message = 'Some error occured while processing the request';
    }
    yield put(saveGitUrl.failure(message));
    action.callback();
    yield put(hideLoader());
  }
}

export function* watchGitHistorySaga() {
  yield takeLatest(FETCH_GIT_HISTORY_BASE, gitHistorySaga);
}

export function* watchValidatedPluginForCertSaga() {
  yield takeLatest(FETCH_GIT_PLUGIN_LINKAGE_DATA_FOR_CERT, gitValidatedPluginForCertSaga);
}

export function* watchSaveGitSaga() {
  yield takeLatest(SAVE_GIT_URL.BASE, saveGitSaga);
}
