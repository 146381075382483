import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { roleNameConstants } from 'utils/rolesHelper';
import { BASIC_INFORMATION_ROUTE } from 'constants/PPCP';
import Axios from 'axios';
import { API_PREFIX, BASE_URL } from '../../config/apiConfig';
import ApiClient from '../../Services/ApiClient';

const manageHostUser = (isUserSuperAdmin, isSupportUser) => ((isUserSuperAdmin || isSupportUser)
&& (
<li>
  <Link to="/admin/managehostusers">Manage Host Users</Link>
</li>
));
const travelportConfigSettings = (isUserSuperAdmin, isSupportUser) => ((isUserSuperAdmin || isSupportUser)
&& (
<li>
  <Link to="/admin/configuration">Smartpoint Desktop Configuration Settings</Link>
</li>
));
const organizationManagement = (isUserSuperAdmin, isUserTPAdmin, isSupportUser) => ((isUserSuperAdmin || isUserTPAdmin || isSupportUser)
&& (
<li>
  <Link to="/admin/home/organizationDirectory">Organization, user and distribution management</Link>
</li>
));
const platFormProductVersionControl = (isUserSuperAdmin) => (isUserSuperAdmin
&& (
<li>
  <Link to="/admin/distribution">Platform Product Version Control</Link>
</li>
));
const reports = (isUserSuperAdmin, isUserTPAdmin, isSupportUser) => ((isUserSuperAdmin || isUserTPAdmin || isSupportUser)
&& (
<li>
  <Link to="/admin/reports">Reports</Link>
</li>
));
const banner = (isUserSuperAdmin) => ((isUserSuperAdmin)
&& (
<li>
  <Link to="/admin/banner">Banner</Link>
</li>
));
const Administration = (props) => {
  const isValidUser = props.currentUser && props.currentUser.profile && !props.currentUser.expired;
  const isUserSuperAdmin = isValidUser && (props.currentUser.profile.role === roleNameConstants.MarketplaceTeamAdmin.name);
  const isUserTPAdmin = isValidUser && (props.currentUser.profile.role === roleNameConstants.TPAdmin.name);
  const isSupportUser = isValidUser && (props.currentUser.profile.role === roleNameConstants.TPSupport.name);
  const isApplicationCertifier = isValidUser && (props.currentUser.profile.role === roleNameConstants.TPApplicationCertifier.name);
  const httpConfig = {
    baseURL: BASE_URL + API_PREFIX,
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache'
    }
  };
  ApiClient.httpClient = Axios.create(httpConfig);
  return (
    <>
      <h2>Administration</h2>
      <div className="container">
        <ul>
          {isUserTPAdmin
            && (
            <li>
              <Link to={BASIC_INFORMATION_ROUTE}>Add Product Page to Marketplace</Link>
            </li>
            )}
          {(isUserSuperAdmin || isUserTPAdmin || isSupportUser)
            && (
            <li>
              <Link to="/admin/manageUserRoles">Manage User Roles</Link>
            </li>
            )}
          {(isUserSuperAdmin || isApplicationCertifier)
            && (
            <li>
              <Link to="/admin/Certifications/Pending">Manage Application Certifications</Link>
            </li>
            )}
          {(isUserSuperAdmin || isUserTPAdmin || isSupportUser)
            && (
            <li>
              <Link to="/admin/licenses/manageLicenses">Manage Plug-in Licenses</Link>
            </li>
            )}
          {organizationManagement(isUserSuperAdmin, isUserTPAdmin, isSupportUser)}
          {platFormProductVersionControl(isUserSuperAdmin)}
          {reports(isUserSuperAdmin, isUserTPAdmin, isSupportUser)}
          {travelportConfigSettings(isUserSuperAdmin, isSupportUser)}
          {manageHostUser(isUserSuperAdmin, isSupportUser)}
          {banner(isUserSuperAdmin)}
        </ul>
      </div>
    </>
  );
};

Administration.propTypes = {
  currentUser: PropTypes.object
};

const mapStateToProps = state => ({
  currentUser: state.oidc.user
});

export default connect(mapStateToProps, null)(Administration);
