/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimplePrettyCheckBox from '../../FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';

class SelectionTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      selectedEntitiesCount: 0,
      sort: null,
      allSelect: false,
    };
  }

  onChangeFilter = (e) => {
    this.setState({ ...this.state, filter: e.target.value });
  };

  getEntities = () => {
    let { entities } = this.props;
    if (!this.props.search && this.state.filter) {
      const filter = this.state.filter.toLowerCase();
      entities = entities.filter(
        (entity) => entity.name.toLowerCase().includes(filter)
          || (entity.details && entity.details.toLowerCase().includes(filter))
      );
    }
    if (this.state.sort === 'asc') {
      entities = entities.sort((entity1, entity2) => (entity1.name.toLowerCase() > entity2.name.toLowerCase() ? 1 : -1));
      return entities;
    }
    if (this.state.sort === 'desc') {
      entities.sort((entity1, entity2) => (entity1.name.toLowerCase() < entity2.name.toLowerCase() ? 1 : -1));
      return entities;
    }
    return entities;
  };

  getMessagesForSeatSiteLicenses = () => {
    if (!this.props.seatSiteLicenseInfo) return '';
    const maxSelectionsAllowed = this.props.seatSiteLicenseInfo.totalLicenses
      - this.props.seatSiteLicenseInfo.assignedSeatSiteLicenses;
    return (
      <>
        {maxSelectionsAllowed !== 0
          && maxSelectionsAllowed === this.state.selectedEntitiesCount && (
            <div className="alert-assigned-licenses-container">
              <span className="alert-icon" />
              <div className="alert-remaining-text-container">
                <label htmlFor="none">
                  You can not select more
                  {this.props.tabName}
                  {' '}
                  than licenses remaining
                </label>
              </div>
            </div>
        )}
        {maxSelectionsAllowed === 0 && (
          <div className="alert-assigned-licenses-container">
            <span className="alert-icon" />
            <div className="alert-remaining-text-container">
              <label htmlFor="none">
                All licenses for this plug-in are assigned. Remove assignments
                to reassign licenses
              </label>
            </div>
          </div>
        )}
      </>
    );
  };

  getMessageForLicensesRemaining = () => {
    if (!this.props.seatSiteLicenseInfo) return '';
    const maxSelectionsAllowed = this.props.seatSiteLicenseInfo.totalLicenses
      - this.props.seatSiteLicenseInfo.assignedSeatSiteLicenses;
    return (
      <div
        className={`${
          this.props.seatSiteLicenseInfo.totalLicenses
          === this.props.seatSiteLicenseInfo.assignedSeatSiteLicenses
            ? 'background-crimson'
            : 'background-green-licenses'
        } assign-licenses-remaining-quantity`}
      >
        <label htmlFor="none">LICENSES REMAINING</label>
        <label htmlFor="none" className="licenses-remaning-counter">
          {maxSelectionsAllowed}
          {' '}
          of
          {this.props.seatSiteLicenseInfo.totalLicenses}
        </label>
      </div>
    );
  };

  sort = (event) => {
    event.preventDefault();
    if (this.state.sort && this.state.sort === 'asc') {
      this.setState({ ...this.state, sort: 'desc' });
    } else {
      this.setState({ ...this.state, sort: 'asc' });
    }
  };

  toggleAllSelect = (value) => {
    if (!this.props.isUnassignmentTab && this.props.seatSiteLicenseInfo) {
      const maxSelectionsAllowed = this.props.seatSiteLicenseInfo.totalLicenses
        - this.props.seatSiteLicenseInfo.assignedSeatSiteLicenses;
      if (this.props.entities.length > maxSelectionsAllowed) {
        return;
      }
    }
    this.setState({ ...this.state, allSelect: value });
    this.props.toggleAllSelect(value);
  };

  clearResults = (event) => {
    event.preventDefault();
    this.setState({ ...this.state, filter: '' });
    this.props.search('');
  };

  applyFilter = (event) => {
    event.preventDefault();
    if (this.props.search) {
      this.props.search(this.state.filter);
    }
  };

  entitySelectionChanged = (entityId, value) => {
    const totalSelections = this.props.entities.filter((e) => e.isSelected)
      .length;
    const maxSelectionsAllowed = !this.props.isUnassignmentTab && this.props.seatSiteLicenseInfo
      ? this.props.seatSiteLicenseInfo.totalLicenses
          - this.props.seatSiteLicenseInfo.assignedSeatSiteLicenses
      : null;
    const state = { ...this.state };
    if (value) {
      if (
        maxSelectionsAllowed !== 0
        && totalSelections !== maxSelectionsAllowed
      ) {
        const unselectedEntitiesCount = this.props.entities.length - totalSelections;
        const allSelected = unselectedEntitiesCount === 1;
        if (allSelected) {
          state.allSelect = true;
        }
        this.props.onSelect(entityId);
        state.selectedEntitiesCount = totalSelections + 1;
      }
    } else {
      if (this.state.allSelect) {
        state.allSelect = false;
      }
      this.props.onDeselect(entityId);
      state.selectedEntitiesCount = totalSelections - 1;
    }
    this.setState(state);
  };

  render() {
    return (
      <div className="row profile-child-box child-nav-tab no-margin">
        {/* show bar for number of licenses remaining in unassignments tab for seat/site licenses */}
        {this.props.seatSiteLicenseInfo
          && this.props.isUnassignmentTab
          && this.getMessageForLicensesRemaining()}
        <div className="list-items-container list-items-inner-list clearfix">
          <div>
            {/* Search Section */}
            <div className="bottom-border-hr no-space row text-center x-small-text">
              <form
                id="search-enterprise-memebers-form"
                onSubmit={this.applyFilter}
              >
                {/* <div className="col-sm-2 pad-sm space-top-sm">Find:</div> */}
                <div className="col-sm-4 pad-sm">
                  <input
                    className="text-input text-input-sm"
                    value={this.state.filter}
                    onChange={this.onChangeFilter}
                    id="find-a-member-enterprise"
                    placeholder="Search"
                  />
                </div>
                <div className="col-sm-1 pad-sm">
                  <a
                    href="#"
                    className="search-icon"
                    id="find-member-enterprise-button"
                    onClick={this.applyFilter}
                  />
                </div>
                <div className="col-sm-3 pad-sm space-top-sm">
                  <a
                    href="#"
                    id="clear-results-button-member-enterprise"
                    onClick={this.clearResults}
                  >
                    Clear Results
                  </a>
                </div>
              </form>
            </div>
            {/* Headers */}
            <div
              className="bottom-border-hr no-space row pad-top-sm"
              id="member-names-selector-container"
            >
              {/* hide select all functionality in assignments tab if it is done for seat/site license */}
              <span>
                {(this.props.isUnassignmentTab
                  || !this.props.seatSiteLicenseInfo) && (
                  <SimplePrettyCheckBox
                    displayText={this.props.tabLabel}
                    value={this.state.allSelect}
                    onChange={this.toggleAllSelect}
                  />
                )}
                {!this.props.isUnassignmentTab
                  && this.props.seatSiteLicenseInfo && (
                    <label
                      htmlFor="none"
                      className="pad-left pad-right-sm"
                      style={{ float: 'left' }}
                    >
                      {this.props.tabLabel}
                    </label>
                )}
              </span>
              <a
                href="#"
                className="sort-icon search-icon-mag"
                onClick={this.sort}
              />
              {this.props.tabSubLabel && (
                <label
                  htmlFor="none"
                  className={this.props.tabSubLabel === 'Email ID' ? 'list-items-righ-column-header-padding' : 'list-items-righ-column-header-items'}
                >
                  {this.props.tabSubLabel}
                </label>
              )}
            </div>
            {/* Error/Info Messages for seat/site licenses in assignments tab */}
            {this.props.seatSiteLicenseInfo
              && !this.props.isUnassignmentTab
              && this.getMessagesForSeatSiteLicenses()}
            {/* Empty content when there is no data */}
            {(!this.props.entities || this.props.entities.length < 1) && (
              <label
                htmlFor="none"
                className="list-items-empty-label pad-left pad-top"
              >
                None
              </label>
            )}
            {/* List of entities */}
            {(!this.props.entities || this.props.entities.length > 0) && (
              <div className="assign-license-element-list">
                {this.getEntities().map((entity, i) => (
                  <div
                    key={i.toString()}
                    id="enterprise-non-assigned-members"
                    style={{ clear: 'both' }}
                  >
                    <div className={this.props.tabSubLabel === 'Email ID' ? 'col-sm-6' : 'col-sm-7'}>
                      <SimplePrettyCheckBox
                        displayText={entity.name}
                        value={entity.isSelected}
                        onChange={(value) => this.entitySelectionChanged(entity.id, value)}
                      />
                    </div>
                    {this.props.tabSubLabel && (
                      <div className={this.props.tabSubLabel === 'Email ID' ? 'col-sm-5' : 'col-sm-5 break-text'}>
                        <label
                          htmlFor="none"
                          className="list-items-righ-column-items"
                        >
                          {entity.details}
                        </label>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SelectionTabContent.propTypes = {
  search: PropTypes.func,
  tabName: PropTypes.string,
  tabLabel: PropTypes.string,
  tabSubLabel: PropTypes.string,
  entities: PropTypes.array,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  toggleAllSelect: PropTypes.func,
  seatSiteLicenseInfo: PropTypes.object,
  isUnassignmentTab: PropTypes.bool,
};

export default SelectionTabContent;
