import React, { Component } from 'react';
import { Collapsible } from '@atlas-design-system/react';
import GoOutLink from '../common/GoOutLink';
import '@atlas-design-system/core/dist/styles/partials/collapsible.css';

class DeveloperFaqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.DeveloperNetworkURL = 'https://developer.travelport.com/connect/home';
    this.DeveloperSDK = 'https://developer.travelport.com/smartpoint-sdk/home';
  }

  render() {
    /* eslint-disable max-len */
    return (
      <>
        <Collapsible header="How do I offer my product or service in Marketplace?">
          <hr />
          <p className="sb-collapsible-content">
            You&#39;ll need to be a member of Travelport Developer Network and then just talk to your Commercial Account Manager who can get you set up.
            If you&#39;d like to find out more about the Developer Network,
            {' '}
            <GoOutLink linkText="click here" link={this.DeveloperNetworkURL} />
            .
          </p>
        </Collapsible>
        <Collapsible header="What kind of products can I offer?">
          <hr />
          <p className="sb-collapsible-content">
            We&#39;re open to a wide range of products that clearly meet the needs of Travelport agencies. Take a look around the site to see the
            kind of products we&#39;re already listing. One thing we&#39;ll insist on is that you have good testimonials from existing customers,
            and any desktop app will need to be certified by Travelport.
          </p>
        </Collapsible>
        <Collapsible header="My product is chargeable – who will bill the customer?">
          <hr />
          <p className="sb-collapsible-content">Developers handle contracting and billing themselves.</p>
        </Collapsible>
        <Collapsible header="My product needs configuration, can Marketplace help?">
          <hr />
          <p className="sb-collapsible-content">
            We would love to learn about your product&#39;s needs so we can further extend the functionality in Marketplace. As a Travelport Developer Network
            member, you will have a technical specialist who can help gather these details and work with our team.
          </p>
        </Collapsible>
        <Collapsible header="What if I have issues loading my products?">
          <hr />
          <p className="sb-collapsible-content">Your Partner Technical Specialist will help.</p>
        </Collapsible>
        <Collapsible header="Can I upload collateral to support the sale of my product?">
          <hr />
          <p className="sb-collapsible-content">
            For each one of your products, you can upload up to 3 documents on the product page on Travelport Marketplace. You can upload .txt, .xls, .pdf and
            image files. Additionally, you can also update up to 4 images for the product and up to 3 YouTube videos.  For support, please contact your Partner
            Technical Specialist.
          </p>
        </Collapsible>
        <Collapsible header="How do I get access to the Smartpoint Desktop SDK?">
          <hr />
          <p className="sb-collapsible-content">
            All Travelport Development Network members and agencies contracted for Smartpoint can use the SDK.  For an overview,
            {' '}
            <GoOutLink
              linkText="go here"
              link={this.DeveloperSDK}
            />
            .  If you’re not a member, please join!  If you are a member, then contact your Partner Technical Specialist.
          </p>
        </Collapsible>
        <Collapsible header="Who do I contact if I have a support question?">
          <hr />
          <p className="sb-collapsible-content">
            Please start with your Partner Technical Specialist, but you are also welcome to raise a ticket
            via
            {' '}
            <a href="https://newmy.travelport.com/">MyTravelport</a>
            {' '}
            site.
          </p>
        </Collapsible>
        <Collapsible header="What is Travelport software review and how does it work?">
          <hr />
          <div className="sb-collapsible-content">
            <p>
              Travelport software review process evaluates desktop solutions to ensure that they are suitable for access to Travelport&#39;s Production environment.
              This review is mutually beneficial to both Travelport and developers by ensuring that solutions are:
            </p>
            <ul>
              <li>Designed to meet a customer&#39;s business needs.</li>
              <li>Coded to transact efficiently and effectively, thereby avoiding excessive transaction fees.</li>
              <li>Complying with Travelport&#39;s recommendations and best practices.</li>
              <li>Within Travelport&#39;s capacity requirements in order to protect our content to the benefit of all Travelport customers.</li>
            </ul>
            <p>
              Our goal is to review solutions within three weeks of receiving your source code and the other documents (support guide and functional design document).
              Travelport desktop review is not intended to be an endorsement of the developer or product or a guarantee that a product will perform or function as
              described.
            </p>
          </div>
        </Collapsible>
        <Collapsible header="What happens if I need to upgrade my solution after the review has been completed?">
          <hr />
          <p className="sb-collapsible-content">
            You will load a new version of the solution and information regarding the upgrade through Marketplace, and we will approve it for publication. A full
            review may not be necessary, but we reserve the right to re-initiate the evaluation process if we feel your changes warrant the time.
          </p>
        </Collapsible>
        <Collapsible header="What happens when an agency buys my product or service?">
          <hr />
          <p className="sb-collapsible-content">
            You will receive an email advising you that an order has been made. If your product or service is set up as a referral, you will need to contact the
            customer within a few days. If your product is being distributed through Marketplace, you won&#39;t need to take further action.
          </p>
        </Collapsible>

      </>
    );
  }
}

export default DeveloperFaqs;
