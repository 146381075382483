import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
  getlicenseInformation,
  updatelicenseSpcAccess,
  getUnassignedMembers,
  memberSelection,
  pccSelection,
  addAssignments,
  removeAssignments
} from 'reducers/modules/Administration/LicenseAssignments';
import {
  licenseInfoSelector,
  membersSelector,
  assignedMembersEntitiesSelector,
  unassignedMembersEntitiesSelector,
  pccsSelector,
  assignedPccsEntitiesSelector,
  unassignedPccsEntitiesSelector,
  seatSiteLicenseInfoSelector
} from 'reducers/modules/Administration/LicenseAssignments/selector';
import licenseTypes from 'constants/licenseTypes';
import SelectionBox from '../../../common/SelectionBox/SelectionBox';
import ToolTip from '../../../common/ToolTip';
import * as utils from '../../../../utils/Common.Utils';
import LoaderComponent from '../../../common/Loader/LoaderComponent';
import { loadUserProfile } from '../../../../actions';

class ManageLicenseAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platformId: 0,
    };
  }

  componentDidMount() {
    document.title = 'Manage License Assignments';
    const licId = utils.getValueFromQueryString(this.props.location, 'licId');
    const platformid = utils.getValueFromQueryString(this.props.location, 'platformId');
    this.setState({
      platformId: platformid
    });
    this.props.getlicenseInformation(licId);
  }

  callback = () => {
    const licId = utils.getValueFromQueryString(this.props.location, 'licId');
    this.props.getlicenseInformation(licId);
  }

  handleCheck = (e) => {
    const isAccess = e.target.checked;
    const licId = utils.getValueFromQueryString(this.props.location, 'licId');
    this.props.updatelicenseSpcAccess(licId, isAccess, this.callback);
  }

  getMembersAssignmentTab = () => ({
    search: this.props.getUnassignedMembers,
    tabName: 'MEMBERS',
    tabLabel: 'MEMBER NAME',
    tabSubLabel: this.state.platformId === '2' ? 'Email ID' : 'SignOn/PCC/Host',
    entities: this.props.unassignedMembersEntities,
    onSelect: this.props.selectUnassignedMember,
    onDeselect: this.props.unselectUnassignedMember,
    toggleAllSelect: this.props.toggleAllSelectUnassignedMember,
    seatSiteLicenseInfo: this.props.seatSiteLicenseInfo,
  });

  getMembersUnassignmentTab = () => ({
    tabName: 'MEMBERS',
    tabLabel: 'MEMBER NAME',
    tabSubLabel: this.state.platformId === '2' ? 'Email ID' : 'SignOn/PCC/Host',
    entities: this.props.assignedMembersEntities,
    onSelect: this.props.selectAssignedMember,
    onDeselect: this.props.unselectAssignedMember,
    toggleAllSelect: this.props.toggleAllSelectAssignedMember,
    seatSiteLicenseInfo: this.props.seatSiteLicenseInfo,
    isUnassignmentTab: true
  });

  getPccsAssignmentTab = () => ({
    tabName: 'PCCS',
    tabLabel: 'PCC',
    tabSubLabel: '',
    entities: this.props.unassignedPccsEntities,
    onSelect: this.props.selectUnassignedPcc,
    onDeselect: this.props.unselectUnassignedPcc,
    toggleAllSelect: this.props.toggleAllSelectUnassignedPcc,
    seatSiteLicenseInfo: this.props.seatSiteLicenseInfo
  });

  getPccsUnassignmentTab = () => ({
    tabName: 'PCCS',
    tabLabel: 'PCC',
    tabSubLabel: '',
    entities: this.props.assignedPccsEntities,
    onSelect: this.props.selectAssignedPcc,
    onDeselect: this.props.unselectAssignedPcc,
    toggleAllSelect: this.props.toggleAllSelectAssignedPcc,
    seatSiteLicenseInfo: this.props.seatSiteLicenseInfo,
    isUnassignmentTab: true
  });

  getAssignmentTabs = () => {
    const assignmentTabs = [];
    if (this.state.platformId === '2') {
      assignmentTabs.push(this.getMembersAssignmentTab());
    } else if (this.props.licenseInformation.licenseType === licenseTypes.enterprise) {
      assignmentTabs.push(this.getMembersAssignmentTab());
      assignmentTabs.push(this.getPccsAssignmentTab());
    } else if (this.props.licenseInformation.licenseType === licenseTypes.seat) {
      assignmentTabs.push(this.getMembersAssignmentTab());
    } else if (this.props.licenseInformation.licenseType === licenseTypes.site) {
      assignmentTabs.push(this.getPccsAssignmentTab());
    }
    return assignmentTabs;
  }

  getUnassignmentTabs = () => {
    const assignmentTabs = [];
    if (this.state.platformId === '2') {
      assignmentTabs.push(this.getMembersUnassignmentTab());
    } else if (this.props.licenseInformation.licenseType === licenseTypes.enterprise) {
      assignmentTabs.push(this.getMembersUnassignmentTab());
      assignmentTabs.push(this.getPccsUnassignmentTab());
    } else if (this.props.licenseInformation.licenseType === licenseTypes.seat) {
      assignmentTabs.push(this.getMembersUnassignmentTab());
    } else if (this.props.licenseInformation.licenseType === licenseTypes.site) {
      assignmentTabs.push(this.getPccsUnassignmentTab());
    }
    return assignmentTabs;
  }

  render() {
    if (this.props.licenseInformation.loading === undefined || this.props.licenseInformation.loading) {
      return <LoaderComponent show />;
    }
    return (
      <>
        {this.props.isLoading && <LoaderComponent show />}
        <h2>
          Manage Assignments:
          {this.props.licenseInformation.applicationName}
        </h2>
        <div className="container">
          <input
            type="hidden"
            id="gtm-event-label"
            value={`Solution:${this.props.licenseInformation.applicationName} / Developer:${this.props.licenseInformation.applicationDeveloperName}`}
          />
          <div className="row">
            <Link to="/Account/MyProfile?selectedTab=MySolutions">&lt; Back to Purchased Solutions</Link>
          </div>
          <div className="row">
            <div id="assign-licenses-container">
              {(this.state.platformId === '2')
                && (
                  <div>
                    <input type="checkbox" checked={this.props.licenseInformation.isSpcUsrAccessProduct === 'True' ? 'checked' : ''} onChange={(e) => this.handleCheck(e)} />
                    &nbsp; Enable agency users for ordering product using Marketplace
                    {' '}
                    <ToolTip
                      labelName="Selected checkbox allows members to order the product from Marketplace. If unselected, only assigned members can access it via Smartpoint Cloud."
                    />
                    <br />
                    <br />
                  </div>
                )}
              <SelectionBox
                infoMessage={'Make selections from this box to make assignments for the solution in Smartpoint. We are only displaying the first 100 assignment selections. '
                  + 'If you do not see the person(s) you wish to add, you can execute a search to find them.'}
                assignmentTabs={this.getAssignmentTabs()}
                unassignmentTabs={this.getUnassignmentTabs()}
                addAssignments={this.props.addAssignments}
                removeAssignments={this.props.removeAssignments}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

ManageLicenseAssignments.defaultProps = {
  handleCheck: PropTypes.func,
};

ManageLicenseAssignments.propTypes = {
  handleCheck: PropTypes.func,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  /* license information */
  licenseInformation: PropTypes.object,
  getlicenseInformation: PropTypes.func,
  updatelicenseSpcAccess: PropTypes.func,
  seatSiteLicenseInfo: PropTypes.object,
  /* assignments */
  addAssignments: PropTypes.func,
  removeAssignments: PropTypes.func,
  /* members */
  getUnassignedMembers: PropTypes.func,
  selectAssignedMember: PropTypes.func,
  selectUnassignedMember: PropTypes.func,
  unselectAssignedMember: PropTypes.func,
  unselectUnassignedMember: PropTypes.func,
  toggleAllSelectAssignedMember: PropTypes.func,
  toggleAllSelectUnassignedMember: PropTypes.func,
  assignedMembersEntities: PropTypes.array,
  unassignedMembersEntities: PropTypes.array,
  /* pccs */
  selectAssignedPcc: PropTypes.func,
  selectUnassignedPcc: PropTypes.func,
  unselectAssignedPcc: PropTypes.func,
  unselectUnassignedPcc: PropTypes.func,
  toggleAllSelectAssignedPcc: PropTypes.func,
  toggleAllSelectUnassignedPcc: PropTypes.func,
  assignedPccsEntities: PropTypes.array,
  unassignedPccsEntities: PropTypes.array,
  loadUserProfileAction: PropTypes.func,
  userProfile: PropTypes.object,
};

const mapStateToProps = state => ({
  isLoading: licenseInfoSelector(state).loading
    || membersSelector(state).loading
    || pccsSelector(state).loading,
  /* license information */
  licenseInformation: licenseInfoSelector(state),
  seatSiteLicenseInfo: seatSiteLicenseInfoSelector(state),
  /* members */
  assignedMembersEntities: assignedMembersEntitiesSelector(state),
  unassignedMembersEntities: unassignedMembersEntitiesSelector(state),
  /* pccs */
  assignedPccsEntities: assignedPccsEntitiesSelector(state),
  unassignedPccsEntities: unassignedPccsEntitiesSelector(state),
  userProfile: state.profile.userProfile,
});

const mapDispatchToProps = dispatch => ({
  /* license information */
  getlicenseInformation: (data) => dispatch(getlicenseInformation.base(data)),
  updatelicenseSpcAccess: (data1, data2, callback) => dispatch(updatelicenseSpcAccess.base(data1, data2, callback)),
  /* assignments */
  addAssignments: () => dispatch(addAssignments()),
  removeAssignments: () => dispatch(removeAssignments()),
  /* members */
  getUnassignedMembers: (searchText) => dispatch(getUnassignedMembers.base(searchText)),
  selectAssignedMember: (entityId) => dispatch(memberSelection.selectAssignedMember(entityId)),
  selectUnassignedMember: (entityId) => dispatch(memberSelection.selectUnassignedMember(entityId)),
  unselectAssignedMember: (entityId) => dispatch(memberSelection.unselectAssignedMember(entityId)),
  unselectUnassignedMember: (entityId) => dispatch(memberSelection.unselectUnassignedMember(entityId)),
  toggleAllSelectAssignedMember: (value) => dispatch(memberSelection.toggleAllSelectAssignedMember(value)),
  toggleAllSelectUnassignedMember: (value) => dispatch(memberSelection.toggleAllSelectUnassignedMember(value)),
  /* pccs */
  selectAssignedPcc: (entityId) => dispatch(pccSelection.selectAssignedPcc(entityId)),
  selectUnassignedPcc: (entityId) => dispatch(pccSelection.selectUnassignedPcc(entityId)),
  unselectAssignedPcc: (entityId) => dispatch(pccSelection.unselectAssignedPcc(entityId)),
  unselectUnassignedPcc: (entityId) => dispatch(pccSelection.unselectUnassignedPcc(entityId)),
  toggleAllSelectAssignedPcc: (value) => dispatch(pccSelection.toggleAllSelectAssignedPcc(value)),
  toggleAllSelectUnassignedPcc: (value) => dispatch(pccSelection.toggleAllSelectUnassignedPcc(value)),
  loadUserProfileAction: bindActionCreators(loadUserProfile, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageLicenseAssignments);
