/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { DropdownButton, MenuItem } from 'react-bootstrap';
import { DropdownMenu } from '@atlas-design-system/react';
import ToolTip from 'components/common/ToolTip';
import dateHelper from '../../../utils/dateHelper';
import IntegrationStatusModal from './IntegrationStatusModal/IntegrationStatusModal';
import IntegrationStatusHistoryModel from './IntegrationStatusHistoryModel/IntegrationStatusHistoryModel';
import DeleteDraftModal from './DeleteDraftModal/DeleteDraftModal';
import * as Config from '../../../config/apiConfig';
import '@atlas-design-system/core/dist/styles/partials/dropdown-menu.css';

class SolutionTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalIntegrationStatus: false,
      showModalIntegrationHistory: false,
      showModalDeleteDraft: false,
      networkURL: Config.ENVIRONMENT === 'PN' ? 'https://sp.travelport.com/' : 'https://sp-pp.travelport.com/'

    };
    const isAppUnpublished = !props.isDraft && props.isActivated && !props.isPublished;
    this.state.isDisabled = !props.ownApp && (isAppUnpublished || !props.isDeveloperOrgVisible);
  }

  componentDidMount() {
    document.title = 'My Solutions Page';
    this._showTooltip(this.props.acquiredBy, this.names, 31);
    this._showTooltip(this.props.developerName, this.developerName, 21);
  }

  getDistributorsSelected() {
    const {
      hasDirectDownload,
      hasSmartpointIntegration,
      hasSupportFileDownload,
      hasThirdPartyDistribution,
      roleId,
      platformId
    } = this.props;
    let max = 2;
    const displayAssigmentButton = roleId === 1 || roleId === 2 || roleId === 4 || roleId === 12;
    const distributorsSelected = [];
    if (platformId === 2) {
      distributorsSelected.push('Smartpoint Cloud');
      if (displayAssigmentButton) {
        distributorsSelected.push('Smartpoint Cloud Assessment');
      }
      max -= 1;
    } else {
      if (hasDirectDownload) {
        distributorsSelected.push('Direct Download');
        max -= 1;
      }
      if (hasSmartpointIntegration && displayAssigmentButton && max > 0) {
        distributorsSelected.push('Automatic Install Button');
        max -= 1;
      }
      if (hasSmartpointIntegration && !displayAssigmentButton && max > 0) {
        distributorsSelected.push('Automatic Install Link');
        max -= 1;
      }
      if (hasSupportFileDownload && max > 0) {
        distributorsSelected.push('Download File');
        max -= 1;
      }
      if (hasThirdPartyDistribution && max > 0) {
        distributorsSelected.push('Third Party Distribution');
      }
    }
    return distributorsSelected;
  }

  getProductLink(props) {
    if (props.isDraft || (props.ownApp && !props.isActivated)) {
      return `/Preview/${props.seoFriendlyProductName}`;
    }
    return `/Product/${props.seoFriendlyProductName}`;
  }

  getTarget(props) {
    if (props.isDraft || (props.ownApp && !props.isActivated)) {
      return 'blank';
    }
    return '';
  }

  getAppUnpublished(props) {
    const isAppPublshed = !props.isDraft && props.isActivated && !props.isPublished;
    return isAppPublshed;
  }

  getDisabledClassName(state) {
    const classNameValue = state.isDisabled ? '' : 'blueText';
    return classNameValue;
  }

  getPurchaseData(data) {
    const purchaseData = !data.ownApp && data.purchasedOnDateString && data.acquiredBy && !data.isDraft;
    return purchaseData;
  }

  _showTooltip = (text, refContainer, width) => {
    let tooltip = null;
    if (refContainer != null
      && refContainer.innerText.length > width) {
      tooltip = document.createAttribute('title');
      tooltip.value = text;
      refContainer.setAttributeNode(tooltip);
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    window.open(this.state.networkURL, '_blank', 'noopener,noreferrer');
  }

  printDistributor() {
    const {
      isActivated,
      isPublished,
    } = this.props;
    const distributorsSelected = this.getDistributorsSelected();
    let cont = 0;
    const orInfo = <div className="orInfo">-or-</div>;
    const distributors = distributorsSelected.map(item => {
      switch (item) {
        case 'Smartpoint Cloud':
          cont += 1;
          return (
            <React.Fragment key={`${item}${this.props.applicationGuid}`}>
              <div
                className="actionContainer"
                style={{ width: 'auto' }}
              >
                <div>&nbsp;</div>
                <div
                  className="action gtm-direct-download"
                  gtm-event-label={`Solution:${this.props.name} / Developer:${this.props.developerName} / TriggerLocation:My Solutions Page`}
                  onClick={(e) => !this.state.isDisabled && this.handleClick(e)}
                >
                  Access on Smartpoint
                </div>
              </div>
            </React.Fragment>
          );
        case 'Smartpoint Cloud Assessment':
          cont += 1;
          return (
            <React.Fragment key={`${item}${this.props.applicationGuid}`}>
              <div
                className={(isActivated && isPublished) ? 'actionContainer d-block' : 'actionContainer d-none'}
                style={{
                  width: 'auto',
                  marginLeft: '27px'
                }}
              >
                <div>Automatic Install</div>
                <Link
                  className="gtm-plugin-assignment-link"
                  style={{ fontWeight: '100' }}
                  gtm-event-label={`Solution:${this.props.name} / Developer:${this.props.developerName} / TriggerLocation:My Solutions Page`}
                  // to={this.state.isDisabled || this.props.licenseId == null ? '#' : `/admin/licenses/manageLicenseAssignments?licId=${this.props.licenseId}&platformId=${this.props.platformId}`}
                  // onClick={(e) => (this.state.isDisabled || this.props.licenseId == null) && e.preventDefault()}
                  to={`/admin/licenses/manageLicenseAssignments?licId=${this.props.licenseId}&platformId=${this.props.platformId}`}
                >
                  <div
                    className="action gtm-plugin-assignment-link"
                    gtm-event-label={`Solution:${this.props.name} / Developer:${this.props.developerName} / TriggerLocation:My Solutions Page`}
                  >
                    ASSIGNMENTS
                  </div>
                </Link>
              </div>
            </React.Fragment>
          );
        case 'Direct Download':
          cont += 1;
          return (
            <React.Fragment key={`${item}${this.props.applicationGuid}`}>
              <div className="actionContainer">
                <div>Direct Download</div>
                <div
                  className="action gtm-direct-download"
                  gtm-event-label={`Solution:${this.props.name} / Developer:${this.props.developerName} / TriggerLocation:My Solutions Page`}
                  onClick={() => !this.state.isDisabled && this.props.directDownloadProduct(this.props.applicationGuid)}
                >
                  DOWNLOAD NOW
                </div>
              </div>
            </React.Fragment>
          );
        case 'Automatic Install Link':
          cont += 1;
          return (
            <React.Fragment key={`${item}${this.props.applicationGuid}`}>
              {cont > 1 && orInfo}
              <div className="actionContainer" title="This solution is distributed by the solution Developer. Please contact your Agency Administrator to obtain this solution.">
                <div>Automatic Install</div>
                <div className={this.getDisabledClassName(this.state)}>SMARTPOINT INTEGRATOR</div>
              </div>
            </React.Fragment>
          );
        case 'Automatic Install Button':
          cont += 1;
          return (
            <React.Fragment key={`${item}${this.props.applicationGuid}`}>
              {cont > 1 && orInfo}
              <div className="actionContainer">
                <div>Automatic Install</div>
                <Link
                  className="gtm-plugin-assignment-link"
                  style={{ fontWeight: '100' }}
                  gtm-event-label={`Solution:${this.props.name} / Developer:${this.props.developerName} / TriggerLocation:My Solutions Page`}
                  to={this.state.isDisabled || this.props.licenseId == null ? '#' : `/admin/licenses/manageLicenseAssignments?licId=${this.props.licenseId}&platformId=${this.props.platformId}`}
                  onClick={(e) => (this.state.isDisabled || this.props.licenseId == null) && e.preventDefault()}
                >
                  <div
                    className="action gtm-plugin-assignment-link"
                    gtm-event-label={`Solution:${this.props.name} / Developer:${this.props.developerName} / TriggerLocation:My Solutions Page`}
                  >
                    ASSIGNMENTS
                  </div>
                </Link>
              </div>
            </React.Fragment>
          );
        case 'Download File':
          cont += 1;
          return (
            <React.Fragment key={`${item}${this.props.applicationGuid}`}>
              {cont > 1 && orInfo}
              <div className="actionContainer">
                <div>Support File</div>
                <a
                  className="gtm-support-file-download"
                  gtm-event-label={`Solution:${this.props.name} / Developer:${this.props.developerName} / TriggerLocation:My Solutions Page`}
                  href={this.state.isDisabled ? '#' : this.props.supportUrl}
                  onClick={(e) => this.state.isDisabled && e.preventDefault()}
                  style={{ fontWeight: '100' }}
                >
                  <div
                    className="action gtm-support-file-download"
                    gtm-event-label={`Solution:${this.props.name} / Developer:${this.props.developerName} / TriggerLocation:My Solutions Page`}
                  >
                    DOWNLOAD FILE
                  </div>
                </a>
              </div>
            </React.Fragment>
          );
        case 'Third Party Distribution':
          cont += 1;
          return (
            <React.Fragment key={`${item}${this.props.applicationGuid}`}>
              {cont > 1 && orInfo}
              <div className="actionContainer" title="This solution is distributed by the solution Developer. Please contact your Agency Administrator to obtain this solution.">
                <div>Third Party Developer</div>
                <div className={this.getDisabledClassName(this.state)}>DISTRIBUTED BY DEVELOPER</div>
              </div>
            </React.Fragment>
          );
        default:
          return null;
      }
    });
    return distributors;
  }

  handlerDevOrgName() {
    const { isInternalApp, developerName, isDeveloperOrgVisible } = this.props;
    const disableOrgPageLink = this.state.isDisabled || !isDeveloperOrgVisible;
    if (!isInternalApp) {
      return (
        <div ref={ref => { this.developerName = ref; }} className="optionLinks shrinkedTextOnDeveloperName text-margin">
          <Link
            className={disableOrgPageLink ? 'disabled-solution-tile-link text-color' : 'text-color'}
            to={disableOrgPageLink ? '#' : `/DeveloperDirectory/${developerName}`}
            onClick={(e) => disableOrgPageLink && e.preventDefault()}
          >
            {developerName}
          </Link>
        </div>
      );
    }
    return null;
  }

  handlerSupport() {
    const { supportUrl } = this.props;
    return (
      <a
        href={this.state.isDisabled ? '#' : supportUrl}
        className="text-margin text-color"
        onClick={(e) => this.state.isDisabled && e.preventDefault()}
      >
        Support&nbsp;&nbsp;
      </a>
    );
  }

  integrationStatusModalOpen = () => this.setState({ showModalIntegrationStatus: true });

  integrationStatusModalClose = () => this.setState({ showModalIntegrationStatus: false });

  integrationStatusHistoryModalOpen = () => this.setState({ showModalIntegrationHistory: true });

  integrationStatusHistoryModalClose = () => this.setState({ showModalIntegrationHistory: false });

  deleteDraftModalOpen = () => this.setState({ showModalDeleteDraft: true });

  deleteDraftModalClose = () => this.setState({ showModalDeleteDraft: false });

  handleManageSolutionDropDown = (ownApp, applicationId, isDraft, applicationGuid, roleId, isInternalApp) => (
    <>
      {this.props.selectedTab !== 'inHouseSolutions' ? <div className="separator separatorAlign" hidden>|</div> : null}
      <div className="optionLinks btn-default atlsAlign">
        <DropdownMenu
          id={applicationId}
          title="Manage Solution"
          style={
            {
              textAlign: 'left',
              fontSize: '12px',
              paddingLeft: '0px',
              paddingBottom: '10px'
            }
          }
          trigger="Manage Solution"
        >
          {isDraft
            && (
              <DropdownMenu.Item className="menuItemCustom" componentClass="span">
                <Link to={`/application/basicinformation?publicid=${applicationGuid}`} className="text-link-black"> Edit Draft </Link>
              </DropdownMenu.Item>
            )}
          {isDraft
            && (
              <DropdownMenu.Item className="menuItemCustom" componentClass="span">
                <Link to="#" onClick={this.deleteDraftModalOpen} className="text-link-black"> Delete Draft </Link>
              </DropdownMenu.Item>
            )}
          {ownApp && !isDraft
            && (
              <DropdownMenu.Item className="menuItemCustom" componentClass="span">
                <Link to={`/application/basicInformation?publicId=${applicationGuid}`} className="text-link-black">
                  {' '}
                  {isInternalApp === false ? 'Edit Internal Solution' : 'Edit Solution'}
                  {' '}
                </Link>
              </DropdownMenu.Item>
            )}
          {ownApp && !isDraft
            && (
              <DropdownMenu.Item className="menuItemCustom" componentClass="span" hidden={this.buttonPublish() === null ? 'hidden' : ''}>
                <Link to="#" onClick={this.handlerUpdatePublish} className="text-link-black">
                  {' '}
                  {this.buttonPublish()}
                  {' '}
                </Link>
              </DropdownMenu.Item>
            )}
          {ownApp
            && (
              <DropdownMenu.Item className="menuItemCustom" componentClass="span">
                <Link to={`/application/review?publicid=${applicationGuid}`} className="text-link-black">
                  {' '}
                  {'Add Solution Files'}
                  {' '}
                </Link>
              </DropdownMenu.Item>
            )}
          {ownApp && (this.props.roleId === 1)
            && (
              <DropdownMenu.Item className="menuItemCustom" componentClass="span">
                <Link to={`/admin/auditLog?id=${applicationId}&category=product`} className="text-link-black"> Audit Logs </Link>
              </DropdownMenu.Item>
            )}
        </DropdownMenu>
      </div>
    </>
  );

  handleViewOptionDropDown = (ownApp, applicationId, isDraft, hasSmartpointIntegration, isInternalApp) => (
    <>
      <div className="separator separatorAlign">|</div>
      <div className="optionLinks btn-default atlsAlign">
        <DropdownMenu
          id={applicationId}
          title="View Options"
          style={
            {
              textAlign: 'left',
              fontSize: '12px',
              paddingLeft: '0px',
              paddingBottom: '10px'
            }
          }
          trigger="View Options"
        >
          {ownApp && isDraft === false
            && <DropdownMenu.Item className="divider" divider hidden />}
          {ownApp && !isDraft && hasSmartpointIntegration && isInternalApp === false
            && (
              <DropdownMenu.Item className="menuItemCustom" componentClass="span">
                <Link to={`/Admin/Licenses/EditLicenseStatus/${applicationId}?searchType=Plugin`} className="text-link-black"> Manage Product Licenses </Link>
              </DropdownMenu.Item>
            )}
          {isDraft === false && hasSmartpointIntegration
            && (
              <DropdownMenu.Item componentClass="span">
                <div className="menuItemCustom text-link-black" onClick={this.integrationStatusModalOpen}>SP Integration Status</div>
              </DropdownMenu.Item>
            )}
          {isDraft === false && hasSmartpointIntegration
            && (
              <DropdownMenu.Item className="menuItemCustom text-link-black" componentClass="span">
                <div onClick={this.integrationStatusHistoryModalOpen}>Integration Status History</div>
              </DropdownMenu.Item>
            )}
        </DropdownMenu>
      </div>
    </>
  );

  handlerViewOptionItems() {
    const {
      ownApp, hasSmartpointIntegration, isDraft, applicationId, isInternalApp, applicationGuid, roleId
    } = this.props;
    if (this.state.isDisabled && hasSmartpointIntegration) {
      return (
        <>
          <div className="separator">|</div>
          <button
            aria-haspopup="true"
            aria-expanded="false"
            type="button"
            className="optionLinks dropdown-toggle btn btn-default"
          >
            View Options
            {' '}
            <span className="caret" />
          </button>
        </>
      );
    }
    if (hasSmartpointIntegration) {
      return (this.handleViewOptionDropDown(ownApp, applicationId, isDraft, applicationGuid, hasSmartpointIntegration, roleId, isInternalApp));
    }
    return null;
  }

  handlerManageSolutionItems() {
    const {
      ownApp, hasSmartpointIntegration, isDraft, applicationId, isInternalApp, applicationGuid, roleId
    } = this.props;
    if (this.state.isDisabled) {
      return (
        <>
          <div className="separator text-margin">|</div>
          <div className="optionLinks btn-default atlsAlign">
            <DropdownMenu
              title="Manage Solution"
              style={
                {
                  textAlign: 'left',
                  fontSize: '12px',
                  paddingLeft: '0px',
                  paddingBottom: '11px'
                }
              }
              trigger="Manage Solution"
            >
              Manage Solution
              {' '}
            </DropdownMenu>
          </div>
        </>
      );
    }
    if (ownApp) {
      return (this.handleManageSolutionDropDown(ownApp, applicationId, isDraft, applicationGuid, hasSmartpointIntegration, roleId, isInternalApp));
    }
    return null;
  }

  publishSection() {
    const {
      isDraft,
      lastUpdatedDate,
      isActivated,
      isPublished,
      publishedDate,
      unpublishedDate,
      ownApp
    } = this.props;
    if (isDraft) {
      return (
        <div>{`Last Updated on ${dateHelper.getDate(lastUpdatedDate)}`}</div>
      );
    } if (isActivated && isPublished) {
      const date = dateHelper.getDate(publishedDate);
      return date ? (
        <div>{`Published on ${date}`}</div>
      ) : '';
    } if (isActivated) {
      const date = dateHelper.getDate(unpublishedDate);
      return date ? (
        <div>{`Unpublished on ${date}`}</div>
      ) : '';
    } if (ownApp) {
      return (
        <div>Submitted</div>
      );
    }
    return null;
  }

  linksWhenDraft() {
    const { isDraft, ownApp, isInternalApp } = this.props;
    if (!isDraft && !ownApp) {
      return (
        <>
          {this.handlerDevOrgName()}
          {!isInternalApp
            && <div className="separator text-margin">|</div>}
          {this.handlerSupport()}
        </>
      );
    }
    /* if (isDraft) {
      return (
        <React.Fragment>
          <Link to={`/application/basicinformation?publicid=${applicationGuid}`} className="optionLinks">Edit Draft</Link>
          <div className="separator">|</div>
          <div className="optionLinks" onClick={this.deleteDraftModalOpen}>Delete Draft</div>
        </React.Fragment>);
    } */
    return null;
  }

  handlerUpdatePublish = (e) => {
    const { updatePublishStatus, applicationId, isPublished } = this.props;
    e.preventDefault();
    const data = { productId: applicationId, publish: !isPublished };
    updatePublishStatus(data);
  }

  buttonPublish() {
    const {
      isPublished, isActivated, isDraft, ownApp
    } = this.props;
    if (isActivated && !isDraft && ownApp) {
      if (isPublished) return 'Unpublish';
      return 'Publish';
    }
    return null;
  }

  aditionalInfo = (data) => {
    if (data.isInternalApp) {
      return (
        <>
          {data.lastUpdatedDateString && <div>{`Last Updated: ${data.lastUpdatedDateString}`}</div>}
          {data.createdBy && <div ref={ref => { this.names = ref; }} className="shrinkedTextOnPurchase">{`Created By: ${data.createdBy}`}</div>}
        </>
      );
    }
    if (data.ownApp && (data.isDraft || (!data.isDraft && data.isActivated && (data.purchasedOnDateString === '1/1/0001'
      || data.purchasedOnDateString === '01/01/0001') && !data.acquiredBy))) {
      return (
        <>
          <div>{`Last Updated: ${data.lastUpdatedDateString}`}</div>
          <div ref={ref => { this.names = ref; }} className="shrinkedTextOnPurchase">{`Created By: ${data.createdBy}`}</div>
        </>
      );
    } if (!data.isDraft && data.ownApp && !data.isActivated) {
      return (
        <>
          <div>{`Submitted For Review: ${data.lastUpdatedDateString}`}</div>
          <div ref={ref => { this.names = ref; }} className="shrinkedTextOnPurchase">{`Created By: ${data.createdBy}`}</div>
        </>
      );
    } if (this.getPurchaseData(data)) {
      return (
        <>
          <div>{`Purchase Date: ${data.purchasedOnDateString}`}</div>
          <div ref={ref => { this.names = ref; }} className="shrinkedTextOnPurchase">{`Purchased By: ${data.acquiredBy}`}</div>
          <div>{data.licenseType && `License Type: ${data.licenseType}`}</div>
        </>
      );
    } if (data.ownApp && !data.isDraft && data.purchasedOnDateString && data.acquiredBy) {
      return (
        <>
          <div ref={ref => { this.developerName = ref; }} className="shrinkedTextOnPurchase">{`Created By: ${data.developerName}`}</div>
          <div ref={ref => { this.names = ref; }} className="shrinkedTextOnPurchase">{`Purchased By: ${data.acquiredBy}`}</div>
          <div>{data.licenseType && `License Type: ${data.licenseType}`}</div>
        </>
      );
    }
    return null;
  }

  solutionTile() {
    const p = this.props;
    let cssClass = '';
    if (p.isInternalApp) {
      cssClass = 'internal solutions-list-internal-app ';
    }
    if (p.isDraft) {
      cssClass = 'draft solutions-list-draft-app';
    }
    if (this.state.isDisabled) {
      cssClass = `${cssClass} solutions-list-disabled-app`;
    }

    const productLink = this.getProductLink(p);
    const target = this.getTarget(p);
    const isAppUnpublished = this.getAppUnpublished(p);
    const disableProductPageLink = !this.props.isDeveloperOrgVisible || isAppUnpublished;
    return (
      <div className={`solution-tile ${cssClass}`}>
        {(this.state.showModalIntegrationStatus)
          && (
            <IntegrationStatusModal
              showModal={this.state.showModalIntegrationStatus}
              popUpHide={this.integrationStatusModalClose}
              solutionProps={p}
              updateStatusAction={this.props.updateStatusAction}
            />
          )}
        {(this.state.showModalIntegrationHistory)
          && (
            <IntegrationStatusHistoryModel
              showModal={this.state.showModalIntegrationHistory}
              popUpHide={this.integrationStatusHistoryModalClose}
              licenseId={p.licenseId}
            />
          )}
        {(this.state.showModalDeleteDraft)
          && (
            <DeleteDraftModal
              showModal={this.state.showModalDeleteDraft}
              popUpHide={this.deleteDraftModalClose}
              applicationGuid={p.applicationGuid}
              deleteDraftAction={this.props.deleteDraftAction}
            />
          )}
        <div className="principal">
          <img className="logo" src={p.logoUrl || require('Styles/img/logo-placeholder-square.svg')} alt="Logo" />
          <div className="links ">
            <Link
              target={target}
              to={disableProductPageLink ? '#' : productLink}
              className={disableProductPageLink ? 'title disabled-solution-tile-link' : 'title'}
              onClick={(e) => disableProductPageLink && e.preventDefault()}
            >
              {p.name}
              {this.props.isInternalApp && <span className="space-left-sm label solutions-internal-app-label">Internal</span>}
              {this.props.isDraft && <span className="space-left-sm label solutions-draft-app-label">Draft</span>}
            </Link>
            <div className="formatLinks formatDrpDown">
              {this.linksWhenDraft()}
              {this.handlerManageSolutionItems()}
              {this.handlerViewOptionItems()}
            </div>
          </div>
        </div>
        <div className="publish">
          {this.publishSection()}
          {/*  <div className={this.buttonPublish() !== null ? 'action' : null} onClick={this.handlerUpdatePublish}>
            {this.buttonPublish()}
          </div> */}
        </div>
        <div className="meta">
          {this.aditionalInfo(p)}
        </div>
        <div className="acquire">
          {this.printDistributor()}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isDisabled) {
      return (
        <ToolTip hideInfoIcon show labelName="This product is currently unavailable on Travelport Marketplace.">
          {this.solutionTile()}
        </ToolTip>
      );
    }
    return this.solutionTile();
  }
}

SolutionTile.propTypes = {
  purchasedOnDate: propTypes.string,
  selectedTab: propTypes.string,
  purchasedOnDateString: propTypes.string,
  sEOFriendlyDeveloperName: propTypes.string,
  acquiredBy: propTypes.string,
  licenseType: propTypes.string,
  platformId: propTypes.number,
  numberOfLicenses: propTypes.number,
  hasDirectDownload: propTypes.bool,
  hasSmartpointIntegration: propTypes.bool,
  hasStandAloneDistribution: propTypes.bool,
  supportsIntegrationDownload: propTypes.bool,
  selectedLicenseStatusOption: propTypes.object,
  licenseStatusDate: propTypes.string,
  licenseStatusDateString: propTypes.string,
  licenseId: propTypes.number,
  createdBy: propTypes.string,
  isDraft: propTypes.bool,
  ownApp: propTypes.bool,
  publishedDate: propTypes.string,
  unpublishedDate: propTypes.string,
  id: propTypes.number,
  lastUpdatedDate: propTypes.string,
  lastUpdatedDateString: propTypes.string,
  isActivated: propTypes.bool,
  applicationId: propTypes.number,
  applicationGuid: propTypes.string,
  name: propTypes.string,
  developerName: propTypes.string,
  isInternalApp: propTypes.bool,
  sEOFriendlyProductName: propTypes.string,
  supportsDirectDownload: propTypes.bool,
  supportsSmartpointIntegration: propTypes.bool,
  hasThirdPartyDistribution: propTypes.bool,
  hasSupportFileDownload: propTypes.bool,
  solutionStatus: propTypes.string,
  isPublished: propTypes.bool,
  logoUrl: propTypes.string,
  supportUrl: propTypes.string,
  isThirdPartyVisible: propTypes.bool,
  isSupportFileVisible: propTypes.bool,
  isSmartpointIntegrationNonAdminVisible: propTypes.bool,
  actions: propTypes.number,
  updatePublishStatus: propTypes.func,
  fetch: propTypes.func,
  directDownloadProduct: propTypes.func,
  roleId: propTypes.number,
  updateStatusAction: propTypes.func,
  deleteDraftAction: propTypes.func,
  isDeveloperOrgVisible: propTypes.bool
};

export default SolutionTile;
